












import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { retrieveTermsOfService, getTermsOfServiceHtml } from '@/api/term-of-service'
import sanitizeHTML from 'sanitize-html'

@Component({
  name: 'TermsOfServicePage',
})
export default class TermsOfServicePage extends Vue {
  @Prop({ default : ''}) private medicalBusinessId! :string
  private content = ''

  @Watch('medicalBusinessId', { immediate: true })
  private async onMedicalBusinessIdChange() {
    if (!this.medicalBusinessId) return
    const privacyPolicy = await retrieveTermsOfService(this.medicalBusinessId)
    const contentUrl = privacyPolicy.file_url ? privacyPolicy.file_url : privacyPolicy.default_file_url
    const result = await getTermsOfServiceHtml(contentUrl)
    this.content = result || ''
  }

  private get sanitizeText() {
    return sanitizeHTML(this.content)
  }
}
